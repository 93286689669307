
.lmds-loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.overlay {
	position: fixed;
	background: white;
	border: 1px solid white;
	z-index: 5;
	height: 100%;
	width: 100%;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	opacity: 0.9;
}